import React, { useState } from "react";
import { Container, Row, Col, Form, Table, Card } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Link, useNavigate  } from "react-router-dom";
import Button from "@mui/material/Button";
import { MenuItem, Tooltip, createTheme, ThemeProvider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

const Recordatorio = () => {
  const [profesionalSeleccionado, setProfesionalSeleccionado] = useState("");
  const [institucionSeleccionada, setInstitucionSeleccionada] = useState("");
  const navigate = useNavigate();

  const handleOptionChangeProfesional = (event) => {
    setProfesionalSeleccionado(event.target.value);
  };

  const handleOptionChangeInstitucion = (event) => {
    setInstitucionSeleccionada(event.target.value);
  };

  //Dialog
  const [open, setOpen] = React.useState(false);
  const [openTwo, setOpenTwo] = React.useState(false);

  //Eliminar
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Editar
  const handleClickOpenTwo = () => {
    setOpenTwo(true);
  };

  const handleCloseTwo = () => {
    setOpenTwo(false);
  };

  //Theme, necesario para manipular el tamaño del texto del icono info
  const theme = createTheme({
    typography: {
      fontSize: 26, // Tamaño de fuente deseado
    },
  });

  return (
    <Container>
      <Row>
        <Col>
          <h1 style={{ textAlign: "left", fontWeight: "bold" }}>
            Agenda de Turnos
          </h1>
        </Col>
      </Row>
      <Row>
        <Divider color="black" />
        <Row xs={1} md={3} className="g-4">
          <Col>
            <Card>
              <Card.Body style={{ textAlign: "left" }}>
                <Row>
                  <Col md={7}>
                    <Card.Title style={{ fontWeight: "bold" }} className="py-2">
                      Turno Nombre
                    </Card.Title>
                  </Col>
                  <Col md={5} style={{ textAlign: "right" }}>
                    <Tooltip title="Turno Activo" placement="right">
                      <EditCalendarIcon />
                    </Tooltip>
                  </Col>
                </Row>
                <Divider />
                <Card.Text>dd/mm/yyy {"-"} hh:mm</Card.Text>
                <Card.Text>Av. Unlugar 1796</Card.Text>
                <Card.Text>Profesional 1</Card.Text>
                <Card.Text>Institucion 1</Card.Text>
                <Card.Text>Comentarios: -</Card.Text>
                <Row className="text-center">
                  <Col md={6}>
                    <Button
                      onClick={handleClickOpenTwo}
                      startIcon={<EditIcon />}
                      variant="contained"
                      className="form-control btn-block"
                    >
                      Editar
                    </Button>
                    <Dialog
                      open={openTwo}
                      onClose={handleCloseTwo}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle className="card-header">
                        <EditIcon />
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <h5>Edicion de Turno</h5>
                          </DialogContentText>
                        </DialogContent>
                        <Form>
                          <Box
                            component="form"
                            sx={{
                              "& > :not(style)": { m: 1, width: "50ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              value="Turno Nombre"
                              fullWidth
                              label="Nombre"
                            />
                            <TextField type="datetime-local" fullWidth />
                            <TextField
                              value="Av. Unlugar 1796"
                              fullWidth
                              label="Direccion"
                            />
                            <TextField
                              style={{ width: "100%" }}
                              select
                              label="Profesional que brinda la atencion (opcional)"
                              value={profesionalSeleccionado}
                              onChange={handleOptionChangeProfesional}
                            >
                              <MenuItem value="opcion1">Profesional 1</MenuItem>
                              <MenuItem value="opcion2">Profesional 2</MenuItem>
                              <MenuItem value="opcion3">Profesional 7</MenuItem>
                            </TextField>
                            <TextField
                              style={{ width: "100%" }}
                              select
                              label="Institucion de Salud (opcional)"
                              value={institucionSeleccionada}
                              onChange={handleOptionChangeInstitucion}
                            >
                              <MenuItem value="opcion1">Institucion 1</MenuItem>
                              <MenuItem value="opcion2">Institucion 2</MenuItem>
                              <MenuItem value="opcion3">
                                Institucion 19
                              </MenuItem>
                            </TextField>
                            <TextField
                              label="Comentarios"
                              value={"-"}
                              multiline
                              rows={4}
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Form>
                        <DialogActions>
                          <Button variant="contained" onClick={handleCloseTwo}>
                            Gurdar
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={handleCloseTwo}
                            autoFocus
                          >
                            Cancelar
                          </Button>
                        </DialogActions>
                      </DialogTitle>
                    </Dialog>
                  </Col>

                  <Col md={6}>
                    <Button
                      onClick={handleClickOpen}
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                      color="error"
                      className="form-control btn-block"
                    >
                      Eliminar
                    </Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle className="card-header">
                        <DeleteForeverIcon />
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            ¿Esta seguro que desea eliminar la Agenda?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Confirmar</Button>
                          <Button onClick={handleClose} autoFocus>
                            Cancelar
                          </Button>
                        </DialogActions>
                      </DialogTitle>
                    </Dialog>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Divider className="my-4" />
        <Col md={12} style={{ textAlign: "left" }}>
          <h2>Agendar nuevo turno</h2>
        </Col>
        <Divider className="" color="black" />

        <Row className="my-5" style={{ textAlign: "left" }}>
          <Col md={6}>
            <TextField fullWidth label="Nombre (opcional)" type="text" />
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Se recomienda definir un nombre para lograr identificar el evento al momdento de recibir el aviso."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField type="datetime-local" fullWidth />
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Momento en que el sistema dará aviso del turno."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField fullWidth label="Direccion (opcional)" type="text" />
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip placement="right" title="Lugar del turno medico." arrow>
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField
              style={{ width: "100%" }}
              select
              label="Profesional que brinda la atencion (opcional)"
              value={profesionalSeleccionado}
              onChange={handleOptionChangeProfesional}
            >
              <MenuItem value="opcion1">Profesional 1</MenuItem>
              <MenuItem value="opcion2">Profesional 2</MenuItem>
              <MenuItem value="opcion3">Profesional 7</MenuItem>
            </TextField>
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="El listado de profesionales se alimenta del menu Profesionales administrado por el usuario."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField
              style={{ width: "100%" }}
              select
              label="Institucion de Salud (opcional)"
              value={institucionSeleccionada}
              onChange={handleOptionChangeInstitucion}
            >
              <MenuItem value="opcion1">Institucion 1</MenuItem>
              <MenuItem value="opcion2">Institucion 2</MenuItem>
              <MenuItem value="opcion3">Institucion 19</MenuItem>
            </TextField>
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="El listado de instituciones se alimenta del menu Instituciones de Salud administrado por el usuario."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField
              label="Comentarios"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
            />
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Tiene la posibilidad de registrar comentarios
								relacionados al turno en caso que lo considere necesario."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={12} style={{ textAlign: "left" }}>
            <Button variant="contained" type="submit" className="my-3">
              Crear Agenda
            </Button>
          </Col>
        </Row>

        <div className="d-flex my-5">
        <Button variant="contained"
          color="primary"
          className="text-white"
          style={{
            textDecoration: "none",
          }}
          component={Link}
          onClick={ () => navigate(-1)}
        >
            Volver
        </Button>
        </div>
      </Row>
    </Container>
  );
};

export default Recordatorio;
