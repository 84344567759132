import React, { useLayoutEffect, useRef, useState } from 'react';
import {
    FormControl,
	TextField,
    input,
    Box,
    Button,
    Autocomplete,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    DialogContentText,
    Divider
  } from "@mui/material";
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { Placeholder } from 'react-bootstrap';

const TextFieldDireccionComponente = ({required, defaultValue, estado, cambiarEstado, type, label, name, leyendaHelper, leyendaError,funcion,readOnly}) => {

const searchInput = useRef(null); // El input donde volcamos la busqueda
const [textoEdit, setTextoEdit] = useState('');
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const apiKey = "AIzaSyDV-VE-kZWkuY8me1scSsv22z-jC0qw97E"; //H&S 

//Cargando la api
const loadAsyncScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = src;
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  };

  const address = {
    locality: "", //Localidad
    route: "", //Direccion
    street_number: "", // Altura
    province: "", //Provincia
    adm_area: "" //Barrio
};

  const handleChangeInput = () => {
    const texto2 = searchInput.current.value;
    setTextoEdit(texto2)
  }

  useLayoutEffect(() => {
    if(searchInput.current.value ==''){
      address.route='';
      address.adm_area='';
      address.locality='';
      address.province='';
      address.street_number='';
      cambiarEstado({...estado, campo: address, valido: true});
    }
  }, [textoEdit]);

	// Extrayendo los datos que nos interesan de la api, podemos agregar o quitar a gusto
  const extractAddress = (place) => {
    

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
	    const values = component.short_name;

      if (types.includes("city")) {
        address.city = value;
      }

			if (types.includes("administrative_area_level_2")) {
        address.adm_area = value;
      }

      if (types.includes("locality")) {
        address.locality = values;
      }

      if (types.includes("street_number")) {
        address.street_number = value;
      }

      if (types.includes("route")) {
        address.route = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.province = value;
      }
    });

    return address;
  };

  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // Obtengo la direccion por cada actualizacion del campo
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    cambiarEstado({...estado, campo: extractAddress(place), valido: true});
  };

  // Activa el autocompletado
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setComponentRestrictions({ 'zIndex': 1400 });
	
	  autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
     
      onChangeAddress(autocomplete)
    );
  };

  useLayoutEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

	
	return (
		<TextField
			fullWidth
			required={required}
			InputProps={{
				readOnly: readOnly,
			  }}
			type={type}
			error={estado.valido == false ? true : false}
			helperText={estado.valido == false ? leyendaError :leyendaHelper}
			id={name}
			label={label}
			inputRef={searchInput}
			valido={estado.valido}
			defaultValue={defaultValue}
      onChange={handleChangeInput}
		/>
			
	);
}
 
export default TextFieldDireccionComponente;