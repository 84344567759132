import React, { useState, useEffect, useLayoutEffect, useReducer, useRef } from "react";
import { Container, Row, Col, Table, Card } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Link, useNavigate  } from "react-router-dom";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import PhotoIcon from "@mui/icons-material/Photo";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";
import { Slider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Tooltip } from "@mui/material";
import { Modal } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";

import TextFieldInputComponente from "../../components/TextFieldInputComponent";
import AxiosHealth from "../../interceptor/axiosHealth";

//Documentos
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import firebaseApp from "../../assets/context";
import AvatarEditor from "react-avatar-editor";

//Tema
import { useThemeContext } from '../../Theme/ThemeContext';

const Perfil = () => {
  const [open, setOpen] = React.useState(false);
  const [agregarContacto, setAgregarContacto] = React.useState(false);
  const [cambiarContraseña, setCambiarContraseña] = React.useState(false);

   /****************************************************/
   const [mostrarForm, setMostrarForm] = useState(true);
   const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
   const form = useRef();
   const navigate = useNavigate();
   /*****************************************************/

  // Tema
  const { contextTheme } = useThemeContext();

  const handleClickOpen = () => {
    //Aplicar editando informacion personal
    setOpen(true);
  };

  const handleClose = () => {
    //Aplicar editando informacion personal
    setOpen(false);
  };

  // Agregar contacto
  const handleClickOpenAgregarContacto = () => {
    setAgregarContacto(true);
  };

  const handleCloseagregarContacto = () => {
    setAgregarContacto(false);
  };

  const [mail, setMail] = useState({ campo: "", valido: null });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const idUsuario = localStorage.getItem("idUsuario");
  
  useLayoutEffect(() => {
    AxiosHealth.get(`/usuarios`)
      .then((res) => {
        setUsuario(res.data) 
        console.log('aca ' + res.data)
      })
      .catch(function (error) {});
    
  }, [reducerValue]);

  useLayoutEffect(() => {
    setMyFiles([]);
    setUsuario([]);
    setMail({ direccion: "", valido: null });
    setTelefono({ direccion: "", valido: null });
    setMostrarForm(true);
  }, [reducerValue]);

  function nuevoContacto() {
    AxiosHealth.post(`usuarios/nuevoContacto`, {
      mail: mail.campo,
      telefono: telefono.campo,
    });

    console.log("Usuario: " + idUsuario);
    console.log(mail.campo);
    console.log(telefono.campo);
  }

  //Cambiar contraseña
  const handleOpenCambiarContraseña = () => {
    setCambiarContraseña(true);
  };

  const handleCloseCambiarContraseña = () => {
    setCambiarContraseña(false);
  };

  //Cambiar imagen de perfil
  const [usuario, setUsuario] = useState([]);
  const [myFiles, setMyFiles] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  useLayoutEffect(() => {
    if (myFiles.length > 1) {
        try{
          AxiosHealth.put(`/usuarios/actualizarImagen`, {
            imgPerfil: myFiles,
          });

        }catch (error){
          console.error(error)
        }
        finally{
          setMostrarForm(false);
          setTimeout(() => forceUpdate(),1000)
          
        }
    }
  }, [myFiles]);

  //Firebase

  const [image, setImage] = useState(null);
  const avatarEditorRef = useRef(null);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImage(imageDataUrl);
      handleOpenModal();
    }
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const [rotation, setRotation] = useState(0);

  const rotarLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const rotarRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const [zoom, setZoom] = useState(1);

  const modificarZoom = (event, newValue) => {
    setZoom(newValue);
  };

  const aumentarZoom = () => {
    if (zoom < 10) {
      setZoom(zoom + 1);
    }
  };

  const disminuirZoom = () => {
    if (zoom > 1) {
      setZoom(zoom - 1);
    }
  };

  const subirImagen = async () => {
    // Obtener la imagen directamente del AvatarEditor
    const canvas = avatarEditorRef.current.getImage();

    // Convertir la imagen del canvas a Blob
    await new Promise((resolve) => canvas.toBlob(resolve)).then((blob) => {
      // Obtener el nombre del archivo original desde el input de tipo file
      const fileInput = document.getElementById("file-input"); // Asigna un id a tu input de tipo file
      const fileName = fileInput.files[0].name;

      // Subir el Blob a Firebase Storage con el nombre del archivo original
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage, "images/" + fileName);

      uploadBytes(storageRef, blob).then(async () => {
        // Obtener la URL de descarga
        const downloadURL = await getDownloadURL(storageRef);
        console.log("URL de descarga:", downloadURL);
        setMyFiles(downloadURL);
        handleCloseModal();
      });
    });
  };

  const inputRef = React.useRef(null);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    (mostrarForm && <>
    <Container className={contextTheme}>
      <Row>
        <Col>
          <h1 style={{ textAlign: "left", fontWeight: "bold" }}>Perfil</h1>
        </Col>
      </Row>
      <Row>
        <Divider color="black" />

        <Row xs={1} md={2} className="g-4" style={{ textAlign: "left" }}>
          <Col>
            <Row>
              <Col md={12}>
                <Card style={{ width: "25rem" }}>
                  {usuario.imgPerfil ? (
                    <Card.Img variant="top" src={usuario.imgPerfil} />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40vh",
                      }}
                    >
                      <Avatar style={{ width: 350, height: 350 }}>
                        <PersonIcon style={{ width: 400, height: 400 }} />
                      </Avatar>
                    </div>
                  )}
                  <Card.Body>
                    <Tooltip
                      title="Cambiar imagen de perfil"
                      placement="bottom"
                    >
                      <div className="file-input-container">
                        <Button variant="contained" onClick={onButtonClick}>
                          Selecciona una imagen
                        </Button>
                        <input
                          type="file"
                          onChange={onFileChange}
                          accept="image/*"
                          ref={inputRef}
                          style={{ display: "none" }}
                          id="file-input"
                        />
                      </div>
                    </Tooltip>
                  </Card.Body>
                  <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    centered
                    size="lg"
                  >
                    <Modal.Body>
                      <div className="artdeco-modal artdeco-modal--layer-default image-selector-modal">
                        <Modal.Header closeButton>
                          <Modal.Title className="custom-title">
                            <b>Actualizar foto</b>
                          </Modal.Title>
                        </Modal.Header>

                        <div>
                          <Stack
                            spacing={2}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                          >
                            <div className="cropContainer">
                              <AvatarEditor
                                ref={avatarEditorRef}
                                image={image}
                                width={320}
                                height={300}
                                border={0}
                                borderRadius={150}
                                scale={zoom}
                                rotate={rotation}
                              />
                            </div>
                            <Stack
                              spacing={2}
                              direction="row"
                              sx={{ width: "100%", mx: "auto" }}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <RotateRightIcon onClick={rotarRight} />
                              <RotateLeftIcon onClick={rotarLeft} />
                            </Stack>

                            <Stack
                              spacing={2}
                              direction="row"
                              sx={{
                                width: 550,
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <RemoveIcon onClick={disminuirZoom} />
                              <Slider
                                aria-label="Volume"
                                value={zoom}
                                onChange={modificarZoom}
                                min={1}
                                max={10}
                                valueLabelDisplay="auto"
                              />
                              <AddIcon onClick={aumentarZoom} />
                            </Stack>
                          </Stack>
                        </div>

                        <Modal.Footer>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              onClick={handleCloseModal}
                            >
                              Cancelar
                            </Button>

                            <Button variant="contained" onClick={subirImagen}>
                              Guardar
                            </Button>
                          </Stack>
                        </Modal.Footer>
                      </div>
                    </Modal.Body>
                  </Modal>
                </Card>
              </Col>
              <Col md={12}></Col>
            </Row>

            <Row className="my-2 text-center" stripped>
              <Col md={12}>
                <Card style={{ width: "25rem", textAlign: "left" }}>
                  <Card.Body>
                    <Card.Title>Emmanuel Lopez</Card.Title>
                    <ul>
                      <li>18/06/1990</li>
                      <li> {usuario.nombre + " " + usuario.apellido} </li>
                    </ul>
                    <Button variant="contained" onClick={handleClickOpen}>
                      Editar informacion personal
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* Arranca segunda seccion */}
          <Col>
            <Card style={{ width: "100%", height: "100%" }}>
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <Card.Title style={{ fontWeight: "bold" }} className="py-2">
                      Contactos alternativos
                    </Card.Title>
                  </Col>
                  <Col md={12}>
                    <Card.Text>
                      Puedes agregar un telefono y configurar un mail
                      alternativo.
                    </Card.Text>

                    <Button
                      variant="contained"
                      onClick={handleClickOpenAgregarContacto}
                    >
                      Agregar
                    </Button>
                    <Dialog
                      open={agregarContacto}
                      onClose={handleCloseagregarContacto}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle>Agregar contacto</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          El sistema permite multiples contactos alternativos.
                        </DialogContentText>

                        <div className="my-2"></div>
                        <TextFieldInputComponente
                          type="text"
                          required
                          // id="mail_inicio_sesion"
                          label="Mail"
                          estado={mail}
                          cambiarEstado={setMail}
                        />
                        <div className="my-2"></div>
                        <TextFieldInputComponente
                          type="text"
                          required
                          // id="mail_inicio_sesion"
                          label="Telefono"
                          estado={telefono}
                          cambiarEstado={setTelefono}
                        />
                      </DialogContent>
                      <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant="contained" onClick={nuevoContacto}>
                          Agregar
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleCloseagregarContacto}
                          autoFocus
                        >
                          Volver
                        </Button>
                      </DialogActions>
                      <Divider className="my-1" />
                    </Dialog>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <Row className="text-center" stripped>
                  <Col>
                    <Table className="my-3" striped bordered hover>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "left" }}>
                            mail@dominio.com
                          </td>
                          <td style={{ textAlign: "left" }}>1134254785</td>
                          <td>
                            <Tooltip title="Editar" placement="bottom">
                              <Button
                                startIcon={<EditIcon />}
                                // onClick={}
                              ></Button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Eliminar" placement="bottom">
                              <Button>
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Divider className="my-5" />

                    {/* Arranca tercera seccion */}
                    <Row>
                      <Col md={12}>
                        <Card.Title
                          style={{ fontWeight: "bold" }}
                          className="py-2"
                        >
                          Seguridad de la cuenta <EnhancedEncryptionIcon />
                        </Card.Title>
                      </Col>
                      <Col md={12}>
                        <Card.Text>
                          Puedes cambiar tu contraseña cada vez que lo desees.
                        </Card.Text>
                        <Button
                          variant="contained"
                          onClick={handleOpenCambiarContraseña}
                        >
                          Cambiar
                        </Button>
                        <Dialog
                          open={cambiarContraseña}
                          onClose={handleCloseCambiarContraseña}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Cambiar contraseña"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText className="my-2">
                              La misma podra ser modificada nuevamente en el
                              futuro si así lo desea.
                            </DialogContentText>
                            <TextField
                              className="my-	"
                              fullWidth
                              label="E-mail"
                              type="text"
                            />
                            <TextField
                              className="my-1"
                              fullWidth
                              label="Contraseña actual"
                              type="text"
                            />
                            <TextField
                              className="my-1"
                              fullWidth
                              label="Nueva contraseña"
                              type="text"
                            />
                          </DialogContent>
                          <DialogActions style={{ justifyContent: "center" }}>
                            <Button
                              variant="contained"
                              onClick={handleCloseCambiarContraseña}
                            >
                              Agregar
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleCloseCambiarContraseña}
                              autoFocus
                            >
                              Volver
                            </Button>
                          </DialogActions>
                          <Divider className="my-1" />
                        </Dialog>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="d-flex my-5">
          <Button variant="contained"
            color="primary"
            className="text-white"
            style={{
              textDecoration: "none",
            }}
            component={Link}
            onClick={ () => navigate(-1)}
          >
              Volver
          </Button>
        </div>
      </Row>
    </Container>
    </>)
  );
};

export default Perfil;
