import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Divider from "@mui/material/Divider";
import FondoRegister from "../../img/Background/FondoLogin.jpg";

//Componentes
import Paper from "@mui/material/Paper";
import { FormControl, FormControlLabel, Radio } from "@mui/material";

//Nueva version de inputs
import { ContenedorTerminos } from "./../../elementos/Formularios";
import Button from "@mui/material/Button";
import Boton from "@mui/material/Button";

import TextFieldInputComponente from "../../components/TextFieldInputComponent";
import TextFieldPassWordComponente from "../../components/TextFieldPasswordComponent";

import Checkbox from "./../../components/Terminos";
import RadioButtonComponent from "../../components/RadioButtonComponent";
import TextFieldDatetimeComponent from "../../components/TextFieldDatetimeComponent";
//import FirstComponent from "../../components/PruebaComponentes";

//Componentes REACT ROUTER DOM
import { Link } from "react-router-dom";
//REACT ROUTER DOM

import Swal from "sweetalert2";
import AxiosHealth from "../../interceptor/axiosHealth";
import ExpReg from "../../elementos/ExpresionesReg";
import Loading from "../../components/Loading";
import "../../css/LoginRegister.css";

//SVG
const Register = () => {
  // function validarCaptcha() {
  //   const tokenCaptcha = captchaRef.current.getValue();
  //   console.log("__________________________________");
  //   console.log(tokenCaptcha);
  //   console.log("__________________________________");
  //   console.log(process.env.REACT_APP_SECRET_KEY);
  //   axios
  //     .post(
  //       `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_SECRET_KEY}&response=${tokenCaptcha}`
  //     )
  //     .then(function (response) {
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  //Validador obligatorio
  const validarObligatorio = (estado, cambiarEstado) => {
    if (estado.valido == null) {
      cambiarEstado({ ...estado, valido: false });
    } else if (estado.valido == "") {
      cambiarEstado({ ...estado, valido: false });
    }
  };

  const captchaRef = useRef(null);

  //Estados como objetos
  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [apellido, setApellido] = useState({ campo: "", valido: null });
  const [mail, setMail] = useState({ campo: "", valido: null });
  const [mail2, setMail2] = useState({ campo: "", valido: null });
  const [dni, setDni] = useState({ campo: "", valido: null });
  const [dni2, setDni2] = useState({ campo: "", valido: null });
  const [password, setPassword] = useState({ campo: "", valido: null });
  const [password2, cambiarPassword2] = useState({ campo: "", valido: null });
  const [fechaNacimiento, setFechaNacimiento] = useState({
    campo: "",
    valido: null,
  });
  const [genero, setGenero] = useState({ campo: "", valido: null });
  const [terminos, setTerminos] = useState({ campo: "", valido: null });
  const [validarReg, setValidarReg] = useState({ campo: "", valido: null });
  const [IsLoading, setIsLoading] = useState(false);
  const [reCaptcha, setReCaptcha] = useState({ campo: "", valido: null });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const checkRequirement = (regex, password) => regex.test(password.campo);
  const allRequirementsMet = ExpReg.requirements.every(req => checkRequirement(req.regex, password));

  const registroUsuario = () => {
    validarObligatorio(nombre, setNombre);
    validarObligatorio(apellido, setApellido);
    validarObligatorio(fechaNacimiento, setFechaNacimiento);
    validarObligatorio(mail, setMail);
    validarObligatorio(mail2, setMail2);
    validarObligatorio(dni, setDni);
    validarObligatorio(dni2, setDni2);
    validarObligatorio(password, setPassword);
    validarObligatorio(password2, cambiarPassword2);
    validarObligatorio(genero, setGenero);
    validarObligatorio(terminos, setTerminos);

    if (
      nombre.valido == true &&
      apellido.valido == true &&
      fechaNacimiento.valido == true &&
      mail.valido == true &&
      dni.valido == true &&
      password.valido == true &&
      allRequirementsMet == true &&
      genero.valido == true &&
      fechaNacimiento.valido == true
    ) {
      console.log("aca");
      setIsLoading(true);
      AxiosHealth.post("/register/pacientes", {
        documento: dni.campo,
        nombre: nombre.campo,
        apellido: apellido.campo,
        fechaNacimiento: fechaNacimiento.campo,
        mail: mail.campo,
        password: password.campo,
        genero: genero.campo,
      })
        .then(ingresarCodigo(), setIsLoading(false))
        .catch((error) => {
          console.log(error.request.response);
          console.log(error.request.status);
          if (error.request.status == "406") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "El mail ya se encuentra registrado, por favor valide el correo y vuelva a intentar.",
            }).then(() => {});
          }
          //setIsLoading(false)
        });
    }
  };

  //Validador de PSS
  const validarPassword2 = () => {
    if (password.campo.length > 0) {
      if (password.campo !== password2.campo) {
        cambiarPassword2((prevState) => {
          return { ...prevState, valido: false };
        });
      } else {
        cambiarPassword2((prevState) => {
          return { ...prevState, valido: true };
        });
      }
    }
  };

  //Validador de Mail
  const validarMail2 = () => {
    if (mail.campo.length > 0) {
      if (mail.campo !== mail2.campo) {
        setMail2((prevState) => {
          return { ...prevState, valido: false };
        });
      } else {
        setMail2((prevState) => {
          return { ...prevState, valido: true };
        });
      }
    }
  };

  //Validador de DNI
  const validarDNI2 = () => {
    if (dni.campo.length > 0) {
      if (dni.campo !== dni2.campo) {
        setDni2((prevState) => {
          return { ...prevState, valido: false };
        });
      } else {
        setDni2((prevState) => {
          return { ...prevState, valido: true };
        });
      }
    }
  };

  useEffect(() => {
    if (validarReg.campo !== "") {
      AxiosHealth.put(`/usuarios/verificarCuenta`, {
        mail: mail.campo,
        codigo: validarReg.campo,
      })
        .then(function (response) {
          if (response.request.status == 201) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title:
                "Su cuenta fue activada correctamente. Será redirigido para que se loguee.",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              AxiosHealth.post(`/historiasMedicas`, {
                idPaciente: response.data.id,
              }).then(function (response) {
                navigate("/");
              });
            });
          }
        })
        .catch((error) => {
          if (error.request.status == 500) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Tiene que ingresar un codigo para validar",
              footer: '<a href="">Necesita ayuda hacer click aqui!</a>',
            }).then(() => {
              ingresarCodigo();
            });
          } else if (error.request.status == 406) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.request.response,
              footer: '<a href="">Necesita ayuda hacer click aqui!</a>',
            }).then(() => {
              navigate("/");
            });
          } else if (error.request.status == 417) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "El codigo ingresado es incorrecto, por favor verifíquedo y vuelva a intentar.",
              footer: '<a href="">Necesita ayuda hacer click aqui!</a>',
            }).then(() => {
              ingresarCodigo();
            });
          }
        });
    }
  }, [validarReg]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const ingresarCodigo = () => {
    Swal.fire({
      title: "Ingrese el código de activacion que recibió por mail",
      input: "text",
      inputPlaceholder: "Ingrese el código de activacion aquí",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      footer: '<a href="">Necesita ayuda hacer click aqui!</a>',
    })
      .then(({ value }) => {
        if (value != "") {
          setValidarReg({ ...validarReg, campo: value });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Tiene que ingresar un codigo para validar",
            footer: '<a href="">Necesita ayuda hacer click aqui!</a>',
          }).then(() => {
            ingresarCodigo();
          });
        }
      })
      .catch((error) => {
        console.log(error.request.response);
        console.log(error.request.status);
      });
  };

  return (
    <>
      {/* <div id="Posicionar-Carta"> */}
      {/* <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            <img src={BotonAtras} id="BotonAtras"></img>
          </Link> */}
      {/* <Paper elevation={1} id="Carta"> */}
      {/* <div id="Posicionar-Container-Register"> */}
      <Row>
        <Col xs={12} md={8}>
          <img
            style={{ backgroundPosition: "left", width: "99%", height: "109%" }}
            src={FondoRegister}
          />
        </Col>
        {IsLoading && <Loading message={"Registrando usuario..."} />}
        <Col xs={12} md={4}>
          <form method="put" className="my-5">
            <Container>
              <h1 style={{ textAlign: "left", fontWeight: "bold" }}>
                Registro de usuario
              </h1>
            </Container>
            <Divider className="my-2" />
            <Container>
              <Row>
                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldInputComponente
                      required
                      type="text"
                      leyendaHelper="Ingrese su nombre."
                      leyendaError="El nombre tiene que ser de 4 a 16 dígitos y solo puede contener letras y espacios."
                      id="Nombre_Usuario_Registrar"
                      label="Nombre"
                      estado={nombre}
                      cambiarEstado={setNombre}
                      expresionRegular={ExpReg.nombre}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldInputComponente
                      required
                      type="text"
                      leyendaHelper="Ingrese su apellido."
                      leyendaError="El apellido tiene que ser de 4 a 16 dígitos y solo puede contener letras y espacios."
                      id="Apellido_Usuario_Registrar"
                      label="Apellido"
                      estado={apellido}
                      cambiarEstado={setApellido}
                      expresionRegular={ExpReg.nombre}
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6} style={{ textAlign: "left" }}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <label style={{ textAlign: "left" }}>
                      Fecha de nacimiento
                    </label>
                    <TextFieldDatetimeComponent
                      required
                      estado={fechaNacimiento}
                      cambiarEstado={setFechaNacimiento}
                      leyendaHelper={"Seleccione la fecha de nacimiento"}
                      leyendaError="Debe ser mayor de 18 años."
                      mayorDeEdad
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <div
                      className="col-md-12 my-1"
                      style={{
                        marginLeft: "0px",
                        border: "1px solid #ced4da", // Color y ancho del borde
                        padding: "8px 10px", // Ajusta el relleno según lo necesites
                        width: "100%",
                      }}
                    >
                      <label style={{ textAlign: "left" }}>Sexo al nacer</label>
                      <Divider />
                      <RadioButtonComponent
                        required
                        estado={genero}
                        cambiarEstado={setGenero}
                        leyendaError="Debe seleccionar el genero."
                      >
                        <FormControlLabel
                          required
                          value="FEMENINO"
                          control={<Radio />}
                          label="F"
                        />
                        <FormControlLabel
                          value="MASCULINO"
                          control={<Radio />}
                          label="M"
                        />
                      </RadioButtonComponent>
                    </div>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldInputComponente
                      required
                      type="text"
                      leyendaHelper="DNI"
                      leyendaError="El DNI deben ser solo numeros."
                      id="Dni_Usuario_Registrar"
                      label="DNI"
                      estado={dni}
                      cambiarEstado={setDni}
                      expresionRegular={ExpReg.dni}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldInputComponente
                      required
                      type="text"
                      leyendaHelper="Repetir su DNI"
                      leyendaError="El numero de DNI deben coincidir."
                      id="Dni_Repetir_Usuario_Registrar"
                      label="DNI"
                      estado={dni2}
                      cambiarEstado={setDni2}
                      funcion={validarDNI2}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldInputComponente
                      required
                      type="text"
                      leyendaHelper="mail@dominio"
                      leyendaError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo."
                      id="Mail_Usuario_Registrar"
                      label="Correo electronico"
                      estado={mail}
                      cambiarEstado={setMail}
                      expresionRegular={ExpReg.correo}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldInputComponente
                      required
                      type="text"
                      leyendaHelper="Repetir su correo: mail@dominio"
                      leyendaError="Los correos deben coincidir."
                      id="Mail_Repetir_Usuario_Registrar"
                      label="Correo electronico"
                      estado={mail2}
                      cambiarEstado={setMail2}
                      funcion={validarMail2}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldPassWordComponente
                      required
                      type="text"
                      leyendaHelper="Ingrese su contraseña."
                      leyendaError="La contraseña tiene que ser de 4 a 12 dígitos."
                      id="Contraseña_Usuario_Registrar"
                      label="Ingrese contraseña"
                      estado={password}
                      cambiarEstado={setPassword}
                      expresionRegular={ExpReg.password}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <TextFieldPassWordComponente
                      required
                      type="text"
                      leyendaHelper="Repita su contraseña."
                      leyendaError="Las contraseñas deben coincidir."
                      id="Contraseña_Usuario_Registrar"
                      label="Repetir Contraseña"
                      estado={password2}
                      cambiarEstado={cambiarPassword2}
                      funcion={validarPassword2}
                    />
                  </FormControl>
                </Col>
                <ul>
                      {ExpReg.requirements.map((req, index) => (
                        <li
                        key={index}
                        style={{
                          color: checkRequirement(req.regex, password) ? 'green' : 'red',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ marginRight: '8px' }}>
                          {checkRequirement(req.regex, password) ? '✔' : '✘'}
                        </span>
                        {req.label}
                      </li>
                      ))}
                    </ul>
               

                <Divider className="my-3" />
                {/* <Col xs={12} style={{ textAlign: "center" }}>
                  <FormControl required>
                    <ReCAPTCHA
                      required
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                      onChange={validarCaptcha}
                    />
                  </FormControl>
                </Col> */}

                <Col xs={12} md={12} style={{ textAlign: "left" }}>
                  <FormControl
                    className="my-1"
                    style={{ width: "100%" }}
                    method="post"
                    id="Input"
                  >
                    <ContenedorTerminos>
                      <Checkbox
                        sx={{ marginLeft: "-100px" }}
                        texto="Acepto los Terminos y Condiciones"
                        estado={terminos}
                        cambiarEstado={setTerminos}
                        leyendaError="Debe aceptar los terminos y condiciones para registrarse."
                      />
                    </ContenedorTerminos>
                  </FormControl>
                </Col>

                <Divider className="my-2" />

                <Col xs={6} md={6} style={{ textAlign: "left" }}>
                  <Boton
                    sx={{ width: "100%" }}
                    variant="contained"
                    id="Boton"
                    onClick={() => registroUsuario()}
                  >
                    Registrarme
                  </Boton>
                </Col>

                <Col xs={6} md={6} style={{ textAlign: "left" }}>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Button 
                      variant="contained"
                      sx={{ width: "100%", backgroundColor: "grey" }}
                      className="text-white"
                      style={{
                        textDecoration: "none",
                      }}
                      component={Link}
                      onClick={ () => navigate(-1)}
                    >
                        Volver
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </form>
        </Col>
      </Row>
      {/* </div> */}
      {/* </Paper> */}
      {/* </div> */}
    </>
  );
};

export default Register;
