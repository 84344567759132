
import React, { useState } from "react";
import { Container, Row, Col, Form, Table, Card } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Link, useNavigate  } from "react-router-dom";
import Button from "@mui/material/Button";
import { MenuItem, Tooltip, createTheme, ThemeProvider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import InfoIcon from "@mui/icons-material/Info";

//Elementos material para alertas

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';


const Recordatorio = () => {
  const [nombre, setNombre] = useState("");
  const [droga, setDroga] = useState("");
  const [medicamento, setMedicamento] = useState("");
  const [recordatorio, setRecordatorio] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [repeticiones, setRepeticiones] = useState("");
  const [horaInicio, setHoraInicio] = useState("");
  const [recordatorioRepeticion, setRecordatorioRepeticion] = useState("");
	const [medicamentoSelector, setMedicamentoSelector] = useState("");
  const [repeticionSelector, setRepeticionSelector] = useState("");
  const navigate = useNavigate();

  const handleOptionChangeRepeticionSelector = (event) => {
    setRepeticionSelector(event.target.value);
  };

  const handleOptionChangeMedicamentoSelector = (event) => {
    setMedicamentoSelector(event.target.value);
  };

  const agregarMedicacion = (e) => {
    e.preventDefault();
    const nuevaMedicacionHabitual = {
      nombre,
      droga,
      medicamento,
    };
    setMedicamento([...medicamento, nuevaMedicacionHabitual]);
    limpiarFormulario();
  };

  const limpiarFormulario = () => {
    setNombre("");
    setDroga("");
    setMedicamento("");
  };

  //Dialog
  const [open, setOpen] = React.useState(false);
  const [openTwo, setOpenTwo] = React.useState(false);

  //Eliminar
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Editar
  const handleClickOpenTwo = () => {
    setOpenTwo(true);
  };

  const handleCloseTwo = () => {
    setOpenTwo(false);
  };

  //Recordatorio
  const [avisoReposicionSeleccionado, setAvisoReposicionSeleccionado] =
    useState("");
  const handleOptionChangeReposicion = (event) => {
    setAvisoReposicionSeleccionado(event.target.value);
  };

  const agregarRecordatorio = (e) => {
    e.preventDefault();
    const nuevoRecordatorio = {
      nombre,
      cantidad,
      repeticiones,
      horaInicio,
      recordatorioRepeticion,
    };
    setRecordatorio([...recordatorio, nuevoRecordatorio]);
  };

  //Theme, necesario para manipular el tamaño del texto del icono info

  const theme = createTheme({
    typography: {
      fontSize: 26, // Tamaño de fuente deseado
    },
  });

	// Funciones para alertas

const [showSuccess, setShowSuccess] = React.useState(false);
const [showInfo, setShowInfo] = React.useState(false);
const [showWarning, setShowWarning] = React.useState(false);
const [showError, setShowError] = React.useState(false);

const handleShowAlerts = (severity) => {
	switch (severity) {
		case 'success':
			setShowSuccess(true);
			setTimeout(() => setShowSuccess(false), 2000);
			break;
		case 'info':
			setShowInfo(true);
			setTimeout(() => setShowInfo(false), 2000);
			break;
		case 'warning':
			setShowWarning(true);
			setTimeout(() => setShowWarning(false), 2000);
			break;
		case 'error':
			setShowError(true);
			setTimeout(() => setShowError(false), 2000);
			break;
		default:
			break;
	}
}

  return (
<>
    <Container>
		      {showSuccess && (<Alert severity="success">Alerta de confirmacion</Alert>)}
					{showInfo    && (<Alert severity="info">Alerta de informacion.</Alert>)}
					{showWarning && (<Alert severity="warning">Alerta de advertencia</Alert>)}
					{showError   && (<Alert severity="error">Alerta de error.</Alert>)}
      <Row>
        <Col>
          <h1 style={{ textAlign: "left", fontWeight: "bold" }}>
            Recordatorios
          </h1>
        </Col>
      </Row>
      <Row>
        <Divider color="black" />
        <Row xs={1} md={3} className="g-4">
          <Col>
            <Card>
              <Card.Body style={{ textAlign: "left" }}>
                <Row>
                  <Col md={7}>
                    <Card.Title style={{ fontWeight: "bold" }} className="py-2">
                      Levotiroxina
                    </Card.Title>
                  </Col>
                  <Col md={5} style={{ textAlign: "right" }}>
                    <Tooltip title="Recordatorio Activo" placement="right">
                      <AddAlertIcon />
                    </Tooltip>
                  </Col>
                </Row>
                <Divider />
                <Card.Text>Frecuencia del recordatorio: A diario</Card.Text>
                <Card.Text>
                  Aviso de reposicion cuando queden: 5 (Unidades)
                </Card.Text>
                <Card.Text>Medicamento disponibles: 15 (Unidades)</Card.Text>
                <Card.Text>Comentarios: -</Card.Text>
                <Row className="text-center">
                  <Col md={6}>
                    <Button
                      onClick={handleClickOpenTwo}
                      startIcon={<EditIcon />}
                      variant="contained"
                      className="form-control btn-block"
                    >
                      Editar
                    </Button>
                    <Dialog
                      open={openTwo}
                      onClose={handleCloseTwo}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle className="card-header">
                        <EditIcon />
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <h5>
                              Usted esta editando la medicacion:{" "}
                              <spam style={{ fontWeight: "bold" }}>
                                Levotiroxina
                              </spam>
                            </h5>
                          </DialogContentText>
                        </DialogContent>
                        <Form onSubmit={agregarMedicacion}>
                          <Box
                            component="form"
                            sx={{
                              "& > :not(style)": { m: 1, width: "50ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              value="Levotiroxina"
                              fullWidth
                              label="Droga"
                            />
                            <TextField
                              value="Levotiroxina, Sódica 125 Mcg, Comprimido"
                              fullWidth
                              label="Medicamento"
                            />
                          </Box>
                        </Form>
                        <DialogActions>
                          <Button variant="contained" onClick={handleCloseTwo}>
                            Gurdar
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={handleCloseTwo}
                            autoFocus
                          >
                            Cancelar
                          </Button>
                        </DialogActions>
                      </DialogTitle>
                    </Dialog>
                  </Col>

                  <Col md={6}>
                    <Button
                      onClick={handleClickOpen}
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                      color="error"
                      className="form-control btn-block"
                    >
                      Eliminar
                    </Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle className="card-header">
                        <DeleteForeverIcon />
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            ¿Esta seguro que desea eliminar la Medicacion?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Confirmar</Button>
                          <Button onClick={handleClose} autoFocus>
                            Cancelar
                          </Button>
                        </DialogActions>
                      </DialogTitle>
                    </Dialog>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Abm */}

        {/* <Divider className="my-4" />
        <Col md={12} style={{ textAlign: "left" }}>
          <h2>Configuracion de recordatorio</h2>
        </Col>
        <Divider className="" color="black" />

        <Row className="my-5" style={{ textAlign: "left" }}>
          <Col md={6}>
            <TextField
              select
              label="Medicacion habitual"
              value={medicamentoSelector}
              onChange={handleOptionChangeMedicamentoSelector}
              style={{ width: "100%" }}
            >
              <MenuItem value="opcion1">Levotiroxina</MenuItem>
              <MenuItem value="opcion2">Dexametasona</MenuItem>
              <MenuItem value="opcion3">Citalopram</MenuItem>
            </TextField>
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Si no llego a este punto navegando desde una medicacion habitual, debe comenzar seleccionado una
                  para continuar."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField type="datetime-local" fullWidth />
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Primer aviso que hará el recordatorio."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField
              style={{ width: "100%" }}
              select
              label="Frecuencia"
              value={repeticionSelector}
              onChange={handleOptionChangeRepeticionSelector}
            >
              <MenuItem value="opcion1">Unica vez</MenuItem>
              <MenuItem value="opcion2">A diario</MenuItem>
            </TextField>
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Puede programarse para que informe por unica vez o para que informe a diario."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField
              style={{ width: "100%" }}
              select
              label="Deseo que el sistema me informe cuando me queden"
              value={avisoReposicionSeleccionado}
              onChange={handleOptionChangeReposicion}
            >
              <MenuItem value="opcion1">Unidades (0)</MenuItem>
              <MenuItem value="opcion2">Unidades (5)</MenuItem>
              <MenuItem value="opcion3">Unidades (10)</MenuItem>
            </TextField>
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Le informaremos cuando se este por quedar sin
								medicacion."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField
              fullWidth
              label="Unidades disponibles"
              type="number"
            />
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Para que la configuracion del punto anterior
								funcione correctamente, por favor indique cuantas unidades del
								medicamento posee al momento de programar el recordatorio."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={6}>
            <TextField
              label="Comentarios"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
            />
          </Col>
          <Col md={4}>
            <ThemeProvider theme={theme}>
              <Tooltip
                placement="right"
                title="Tiene la posibilidad de registrar comentarios
								relacionados al recordatorio en caso que lo considere necesario."
                arrow
              >
                <InfoIcon sx={{ fontSize: 45 }} />
              </Tooltip>
            </ThemeProvider>
          </Col>

          <Divider className="my-3" />

          <Col md={12} style={{ textAlign: "left" }}>
            <Button variant="contained" type="submit" className="my-3">
              Guardar recordatorio
            </Button>
          </Col>
        </Row> */}

        <div className="d-flex my-5">
        <Button variant="contained"
          color="primary"
          className="text-white"
          style={{
            textDecoration: "none",
          }}
          component={Link}
          onClick={ () => navigate(-1)}
        >
            Volver
        </Button>
        </div>
      </Row>
    </Container>

		<Container>
			<Row>
				<Col>
					<Stack spacing={5}>
					<ButtonGroup variant="outlined" aria-label="Basic button group">
						<Button onClick={() => handleShowAlerts('success')} variant="contained">Confirmacion</Button>{"-"}
						<Button onClick={() => handleShowAlerts('info')} variant="contained">Info</Button>{"-"}
						<Button onClick={() => handleShowAlerts('warning')} variant="contained">Advertencia</Button>{"-"}
						<Button onClick={() => handleShowAlerts('error')} variant="contained">Error</Button>
				</ButtonGroup>
				</Stack>
			</Col>
			</Row>
		</Container>
</>




  );
};

export default Recordatorio;
